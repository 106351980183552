import { FirebaseAuth } from '@smartapp/auth-lib'
import { Settings } from 'luxon'
import { initializeApp } from 'firebase/app'
import 'firebase/auth'

Settings.defaultLocale = window.navigator.language

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

const app = initializeApp(config)

export let globalProperties = {}

new FirebaseAuth(config, app, ['development', 'staging'].includes(process.env.VUE_APP_BUILD_ENV))
  .signIn()
  .catch(signInError => {
    console.warn('Error on authentification', signInError)
    window.location.href = '/error.html'
  })
  .then(a => a.authorization())
  .then(async({ firebaseUser, user, roles }) => {
    globalProperties = {
      firebaseUser,
      user,
      roles,
      isUserFranchise: user?.site?.contractType === 'FRA' || user?.employeeType === 'Affiliate',
      arfFeatures: process.env.VUE_APP_ARF_FEATURES
    }

    if (process.env.VUE_APP_ARF_FEATURES) {
      fetch(`${process.env.VUE_APP_BACKEND_SMART}/application/${process.env.VUE_APP_APP_NAME}`, {
        headers: {
          Authorization: `Bearer ${firebaseUser.accessToken}`,
          project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID,
          application: process.env.VUE_APP_APP_NAME,
          country: process.env.VUE_APP_COUNTRY
        }
      })
        .then(async res => {
          globalProperties.appInfos = (await res.json()).result
        })
        .catch(console.error)
    }

    import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
      if (window.screen.width >= 600) createApp.createApp({ firebaseUser, user, roles })
      else createApp.createMobileApp({ firebaseUser, user, roles })
    })
  })
  .catch(authorizationError => {
    console.warn('Error on authorization', authorizationError)
    import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
      createApp.createForbiddenApp(authorizationError)
    })
  })
